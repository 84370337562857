import React, { } from 'react'

const DashboardAdmin = () => {

  return (
    <>
      <div></div>
    </>
  )
}

export default DashboardAdmin