import React from 'react'

const ModalsMentions = () => {
    return (
        <div className='p-5'>
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Beatae enim aspernatur delectus, odit obcaecati corporis voluptate fuga animi aliquid nostrum rem modi ducimus dolorum voluptatibus quos laboriosam? Maxime, rerum et!
        </div>
    )
}

export default ModalsMentions